import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

/* Indie Components */
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import Section from "../components/Section"

import PageTitleSection from "../components/PageTitleSection"
import BodyText from "../components/BodyText"

import { InlineWidget } from "react-calendly"

const officeImage = "../assets/contact/167-great-portland-street.jpeg"

const DiscoveryCallTemplate = ({ data: { page } }) => {
  return (
    <Layout showFooter={page.pageSettings.showFooter}>
      <Seo seo={page.seo} />
      <MountainHeader headerImages={page.headerSettings.headerImages} />
      <PageWrapper additionalClassName="contact">
        <PageTitleSection
          title={page.headerSettings.title}
          subtitle={page.headerSettings.subTitle}
        />

        <Section additionalClasses="has-background-black-bis">
          <div class="columns">
            <div class="column is-4">
              <div classname="content-padding-address">
                <BodyText
                  padding
                  size="is-size-5 is-size-5-mobile"
                  text="Want to get a feel for how we might be able to help?<br><br>Book in a time for a friendly 15 minute chat and you can tell us all about your project.<br><br> We'll listen, give you some advice and let's see if we hit it off."
                  theme="dark"
                  weight="normal"
                />
              </div>
            </div>
            <div class="column is-7 is-offset-1">
              <InlineWidget
                url="https://calendly.com/indie-ridge/quick-discovery-call"
                styles={{
                  height: "700px",
                }}
                pageSettings={{
                  backgroundColor: "1a1a1a",
                  hideEventTypeDetails: true,
                  hideLandingPageDetails: false,
                  primaryColor: "fff200",
                  textColor: "ffffff",
                }}
              />
            </div>
          </div>
        </Section>
        <Section>
          <div className="columns is-vcentered">
            <div className="column is-6">
              <StaticImage
                src={officeImage}
                alt="167-169 Great Portland Street"
                placeholder="blurred"
                layout="constrained"
                width={650}
              />
            </div>
            <div className="content-padding-address column is-offset-1 is-5 is-size-4 is-size-4-mobile has-text-centered-mobile">
              <div className="contact-address ">
                Indie Ridge Limited
                <br />
                167-169 Great Portland Street
                <br />
                5th Floor
                <br />
                London
                <br />
                W1W 5PF
                <br />
                <br />
                0203 475 6837 <br />
                <br className="is-hidden-mobile" />
                <a href="mailto:hello@indieridge.com">hello@indieridge.com</a>
              </div>
            </div>
          </div>
        </Section>
      </PageWrapper>
    </Layout>
  )
}

export default DiscoveryCallTemplate

export const DiscoveryCallTemplateQuery = graphql`
  query DiscoveryCallPageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      ...pageFields
    }
  }
`
